import { Pagination } from 'src/types';
import { RdrAlert } from './../../types/rdrAlerts';

export const SET_RDR_ALERTS = 'SET_RDR_ALERTS';
export const SET_PAGINATION = 'SET_PAGINATION';
export const SET_LOADING = 'SET_LOADING';

export type SetRdrAlerts = {
    type: typeof SET_RDR_ALERTS;
    payload: RdrAlert[];
};

export type SetPagination = {
    type: typeof SET_PAGINATION;
    payload?: Pagination;
};

export type SetLoading = {
    type: typeof SET_LOADING;
};

export type RdrAlertsActionTypes = SetRdrAlerts | SetLoading | SetPagination;

export const setRdrAlertsAction = (rdrAlerts: RdrAlert[]): SetRdrAlerts => ({
    type: SET_RDR_ALERTS,
    payload: rdrAlerts
});

export const setPaginationAction = (
    pagination?: Pagination
): SetPagination => ({
    type: SET_PAGINATION,
    payload: pagination
});

export const setLoadingAction = () => ({
    type: SET_LOADING
});
