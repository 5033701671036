import {
    BrandableServiceFileFeaturesCommonModelsFileDownloadDto,
    BrandableServiceFileFeaturesCommonModelsUploadFileResult
} from 'src/api/swagger/fileTypes';

export enum FileProcessStatusesEnum {
    PROCESSING = 'Processing',
    COMPLETED = 'Completed'
}

export interface FilePost {
    originalFileName: string;
    description?: string;
}

export type IFilePutResponse = {
    id: string;
    originalFileName: string;
    link: string;
    prefix: string;
};

export interface IFileDownload
    extends BrandableServiceFileFeaturesCommonModelsFileDownloadDto {}

export interface IFilePost
    extends BrandableServiceFileFeaturesCommonModelsUploadFileResult {}
