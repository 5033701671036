import React from 'react';
import { getPartnerKey } from 'src/utils/getGlobalPartnerKey';

import Midkingz from './midkingz.png';
import FraudWrangler from './fraudwrangler.svg';

type PartnerLogoType = {
    width: number;
    height: number;
};

const PartnerLogo = ({
    width,
    height
}: Partial<PartnerLogoType>): JSX.Element => {
    const partnerKey = getPartnerKey();
    let logoSrc;

    switch (partnerKey) {
        case 'midkingz':
            logoSrc = Midkingz;
            break;
        default:
            // Removed logo - https://its.velvetech.com/browse/BVB-559
            logoSrc = '';
            break;
    }

    return <img width={width} height={height} src={logoSrc} />;
};

export default PartnerLogo;
