import { yupResolver } from '@hookform/resolvers/yup';
import { mixed, object, SchemaOf, string, StringSchema } from 'yup';
import {
    bankRoutingValidation,
    notesValidation,
    partnerStatusValidation,
    servicesValidation,
    tokensValidation
} from 'src/utils/yupValidations';
import { MerchantCreateOrUpdate } from 'src/types';

const schema: SchemaOf<MerchantCreateOrUpdate> = object({
    name: string().required().max(100),
    externalIdentifier: string().max(200).nullable(),
    timezone: string().nullable(),
    webSite: string().nullable(),

    mid: string().nullable(),
    dba: string().nullable(),
    mcc: string().nullable(),
    rdrToken: string().nullable(),
    paymentStatus: mixed()
        // .oneOf(
        //     ['GoodStanding', 'PastDue', null],
        //     'Must be GoodStanding or PastDue'
        // )
        .optional(),
    recordOfPayments: mixed()
        .oneOf(['Unknown', 'Received', null], 'Must be Unknown or Received')
        .optional(),
    agent: object({
        id: string().nullable()
    }).optional(),
    partner: object({
        id: string().required()
    }),
    accountInfo: object({
        address: string().required().max(500),
        city: string().required().max(50),
        zip: string().required().max(16),
        country: string().required(),
        address2: string().nullable(),
        state: string().required().max(40),
        notes: string().nullable(),
        ...partnerStatusValidation
    }),
    commissionPaymentInfo: object({
        accountHolderType: mixed().oneOf(
            ['Business', 'Personal'],
            'Must be Business or Personal'
        ),
        bankRouting: bankRoutingValidation,
        bankAccount: string().required().max(34) as StringSchema<string>,
        accountType: mixed().oneOf(
            ['Checking', 'Savings'],
            'Must be Checking or Savings'
        )
    }),
    companyContact: object({
        firstName: string().required().max(50),
        lastName: string().required().max(100),
        phoneNumber: string().required().max(15),
        faxNumber: string().max(15),
        emailAddress: string()
            .required()
            .email('Must be a valid email')
            .max(100)
    }),
    ...notesValidation,
    ...tokensValidation,
    ...servicesValidation
});

export default function merchantValidationYupResolver() {
    return yupResolver(schema);
}
