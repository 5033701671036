import { useEffect, useMemo, useState } from 'react';

import { useHistory } from 'react-router';
import { SubmitHandler, useForm } from 'react-hook-form';
import { DevTool } from '@hookform/devtools';
import { Stack, Typography, Button, Box } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { useBillingModels, usePartner, useServices } from 'src/api/hooks';
import { IFilePutResponse, PartnerCreateEditDto } from 'src/types';
import { routes } from 'src/constants';
import { PartnerInfoForm } from 'src/components/Partners/Forms/PartnerInfoForm';
import { CompanyContactForm } from 'src/components/Partners/Forms/CompanyContactForm';
import { CommissionPaymentInfoForm } from 'src/components/Partners/Forms/CommissionPaymentInfoForm';
import { Spin } from 'src/components/Spin/Spin';
import useEditFormId from 'src/api/hooks/useEditFormId';
import partnerFormValidationYupResolver from 'src/pages/Partners/validation';
import StatusCard from 'src/components/Cards/StatusCard';
import useBlockRouting from 'src/hooks/useBlockRouting';
import LeaveFormModal from 'src/components/Modal/LeaveFormModal';
import PrimaryUsernameCard from 'src/components/Cards/PrimaryUsernameCard';

import INITIAL_VALUES from './initialValues';
import NotesCard from 'src/components/Cards/NotesCard/NotesCard';
import ControlledNotesCard from 'src/components/Cards/NotesCard/ControlledNotesCard';
import newPartner from 'src/api/mocks/newPartner';
import { BrandableServicePartnerEnumsStatus } from 'src/api/swagger/partnerTypes';
import { formatPartnerTypeForForm } from 'src/utils/formatters';
import ServicesCard from 'src/components/Partners/ServicesCard';
import { logFormState } from 'src/utils/loggers';
import { generateFields, tableFields } from './servicesFields';
import { FileInput } from 'src/components/Form/FileInput';
import ImagePreview from 'src/components/Cards/ImagesFormCard/ImagePreview';

export const PartnerEdit = () => {
    const history = useHistory();
    const [isEdit, id] = useEditFormId();
    const [isModalToGoOutOpen, setIsModalToGoOutOpen] = useState(false);

    const [buttonLoading, setButtonLoading] = useState(false);
    const {
        fetchPartner,
        partner,
        loading: partnerLoading,
        createPartner,
        updatePartner
    } = usePartner();

    const { unblockAndGo, setCanGoOut } = useBlockRouting(() =>
        setIsModalToGoOutOpen(true)
    );

    const { fetchBillingModels, billingModels } = useBillingModels();

    useEffect(() => {
        fetchBillingModels();
    }, []);

    useEffect(() => {
        if (id) {
            fetchPartner(id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    const { handleSubmit, control, reset, formState, setValue, watch } =
        useForm<PartnerCreateEditDto>({
            defaultValues: INITIAL_VALUES,
            resolver: partnerFormValidationYupResolver()
        });

    const logo = watch('logo');

    useEffect(() => {
        const isDirty = !!Object.keys(formState.dirtyFields).length;
        setCanGoOut(!isDirty);
    }, [formState]);

    useEffect(() => {
        if (partner && id && partner.id === id) {
            reset(formatPartnerTypeForForm(partner));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [partner]);

    const { fetchServices, services, loading: loadingServices } = useServices();

    useEffect(() => {
        void fetchServices({
            pageSize: '1000'
        });
    }, []);

    const onSubmit: SubmitHandler<PartnerCreateEditDto> = data => {
        setButtonLoading(true);

        function handleUnblockAndCancel() {
            unblockAndGo(routes.accountsView);
        }

        if (isEdit && id) {
            updatePartner(id, data, () => handleUnblockAndCancel()).finally(
                () => setButtonLoading(false)
            );
        } else {
            createPartner(data, () => handleUnblockAndCancel()).finally(() =>
                setButtonLoading(false)
            );
        }
    };

    const handleCancel = () => history.push(routes.accountsView);

    const fieldsToDisplay = useMemo(
        () => generateFields(services, billingModels),
        [services, billingModels]
    );

    const onStartUploadFile = () => {};
    const onSuccessUploadFile = (file: IFilePutResponse) => {
        setValue('logo.fileId', file.id);
    };
    const onErrorUploadFile = () => {};

    const handleDeleteImage = (_imageId: string) => {
        setValue('logo', null);
    };

    return (
        <Spin loading={partnerLoading}>
            <Stack spacing={2} flexGrow={1} mb={3}>
                <Box display="flex" justifyContent="space-between">
                    <Typography variant="h5">
                        {isEdit ? 'Edit partner' : 'Create partner'}
                    </Typography>
                    {logo ? (
                        <ImagePreview
                            imageId={logo.fileId as string}
                            onDelete={handleDeleteImage}
                        />
                    ) : (
                        <FileInput
                            title="Upload logo"
                            onStartUploadFile={onStartUploadFile}
                            onSuccessUploadFile={onSuccessUploadFile}
                            onErrorUploadFile={onErrorUploadFile}
                            inputAccept="image/*"
                            mimeTypes={['image/jpeg', 'image/png']}
                            withIcon={false}
                        />
                    )}
                </Box>
                <form>
                    <Stack spacing={2} alignItems="end">
                        <PartnerInfoForm control={control} />
                        <CompanyContactForm control={control} />
                        <ServicesCard
                            modalTitle="Add service to Partner"
                            tableFields={tableFields}
                            modalInputs={fieldsToDisplay}
                            control={control}
                            loading={loadingServices}
                        />
                        <CommissionPaymentInfoForm control={control} />
                        {/*<PrimaryUsernameCard control={control} />*/}
                        <StatusCard control={control} keyWord="Partner" />
                        <ControlledNotesCard name="notes" control={control} />
                        <Stack direction="row" spacing={2}>
                            <Button
                                onClick={handleCancel}
                                variant="outlined"
                                disabled={buttonLoading}
                            >
                                Cancel
                            </Button>
                            <LoadingButton
                                onClick={handleSubmit(onSubmit)}
                                variant="contained"
                                loading={buttonLoading}
                            >
                                {isEdit ? 'Save changes' : 'Continue'}
                            </LoadingButton>
                        </Stack>
                    </Stack>
                </form>
            </Stack>
            <LeaveFormModal
                open={isModalToGoOutOpen}
                onClose={() => setIsModalToGoOutOpen(false)}
                onLeave={() => unblockAndGo()}
                onSave={() => console.log('Save Draft')}
            />
        </Spin>
    );
};
