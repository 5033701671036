import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Typography } from '@mui/material';
import { FC, MouseEvent, useState } from 'react';
import { OrganizationButton } from './styles';

import OrganizationsMenu from 'src/components/OrganizationSelect/_components/OrganizationsMenu';
import { IOrganizationDto } from 'src/types';

export type OnSelectType = (organization: IOrganizationDto) => void;

interface Props {
    selectedOrganization?: IOrganizationDto;
    onSelect: OnSelectType;
}

export const OrganizationSelect: FC<Props> = ({
    selectedOrganization,
    onSelect
}) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleDropdown = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <OrganizationButton
                title={'Select Organization'}
                onClick={handleDropdown}
                size="large"
                endIcon={<KeyboardArrowDownIcon />}
                sx={theme => ({ color: theme.palette.text.primary })}
            >
                <Typography variant="subtitle1">
                    Organization:{' '}
                    {selectedOrganization?.name || 'Select Organization'}
                </Typography>
            </OrganizationButton>
            {anchorEl && (
                <OrganizationsMenu
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    onSelect={onSelect}
                />
            )}
        </>
    );
};
