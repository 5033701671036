export interface UserDto {
    id: string;
    firstName: string;
    lastName: string;
    status: UserStatus;
}

export enum UserStatus {
    Active = 'Active',
    Inactive = 'Inactive'
}

export interface RolesByOrganizationDto {
    organizationId: string;
    organizationName: string;
    roles: UserRole[];
}

export interface UserRolesByOrganizationPost {
    organizationId: string;
    roleIds: string[];
}

export interface UserRole {
    id: string;
    name: string;
}

export interface UserInfoDto {
    id?: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    email: string;
    status: UserStatus;
    userName: string;
    country?: string;
    systemRoles: UserRole[];
    rolesByOrganizations: RolesByOrganizationDto[];
}

export interface UserManagementPost {
    id?: string;
    email: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    country?: string;
    systemRoles: string[];
    rolesByOrganizations: UserRolesByOrganizationPost[];
}
