import React, { useEffect, useRef } from 'react';
import Loader from 'src/components/Loader/Loader';
import { LoaderMenuItem } from 'src/components/OrganizationSelect/styles';

const ObservableLoader = ({
    onIntersection
}: {
    onIntersection: () => void;
}) => {
    const observerLoaderRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            entries => {
                if (entries.find(en => en.isIntersecting)) {
                    onIntersection();
                }
            },
            {
                root: null
            }
        );

        observerLoaderRef.current &&
            observer.observe(observerLoaderRef.current);

        return () => {
            observerLoaderRef.current &&
                observer.unobserve(observerLoaderRef.current);
        };
    }, [observerLoaderRef.current, onIntersection]);

    return (
        <LoaderMenuItem ref={observerLoaderRef}>
            <Loader loading type="fullBlock" />
        </LoaderMenuItem>
    );
};

export default ObservableLoader;
