import { combineReducers } from 'redux';
import { agents } from './agents';
import { message } from './message';
import { services } from './services';
import { shortCategories } from './shortCategories';
import { categories } from './categories';
import { mids } from './mids';
import { billingModels } from './billingModels';
import { auth } from './auth';
import { permissions } from './permissions';
import { rdrAlerts } from './rdrAlerts';
import { routes } from './routes';

export const rootReducer = combineReducers({
    agents,
    message,
    services,
    shortCategories,
    categories,
    billingModels,
    auth,
    routes,
    permissions,
    mids,
    rdrAlerts
});

export type AppState = ReturnType<typeof rootReducer>;
