import { withUnwrappedHandlers } from './../helpers/withDefaultHandlers';
import { ApiResponse, Pagination } from 'src/types';
import {
    RdrAlert,
    RdrAlertPostReqBody,
    RdrAlertPostResponse
} from 'src/types/rdrAlerts';
import { apiClient } from '../client';
import {
    withDefaultHandlers,
    withDefaultHandlersPagination
} from '../helpers/withDefaultHandlers';
import { CommonApiGetParams } from '../../types/common';

const BASE_PATH = '/verifi/rdr-api';

export const apiGetRdrAlerts = ({
    params
}: {
    params?: CommonApiGetParams;
}): Promise<{ result: RdrAlert[]; pagination: Pagination | undefined }> =>
    withDefaultHandlersPagination(
        apiClient.get<ApiResponse<RdrAlert[]>>(BASE_PATH, params)
    );

export const apiPostRdrAlert = (rdrAlert: RdrAlertPostReqBody) =>
    apiClient.post<ApiResponse<RdrAlertPostResponse>>(BASE_PATH, rdrAlert);
