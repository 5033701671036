import { routes } from './routes';
import { Users } from '../pages/Users/Users';
import { Agents } from '../pages/Agents/Agents';
import { AgentForm } from 'src/pages/Agents/forms/AgentForm';
import { Services } from '../pages/Services/Services';
import { ServiceEdit } from '../pages/Services/ServiceEdit';
import { Categories } from '../pages/Categories/Categories';
import { CategoryEdit } from '../pages/Categories/CategoryEdit';
import { BillingModels } from '../pages/BillingModels/BillingModels';
import { BillingModelEdit } from '../pages/BillingModels/BillingModelEdit';
import { Accounts } from 'src/pages/Accounts/Accounts';
import { PartnerEdit } from '../pages/Partners/PartnerEdit';
import { MerchantEdit } from '../pages/Merchants/MerchantEdit';
import { Login } from '../pages/Auth/Login';
import { CreateAccount } from '../pages/Auth/CreateAccount';
import { Organizations } from '../pages/Organizations/Organizations';
import { CreateOrganization } from '../pages/Organizations/CreateOrganization';
import { Dashboard } from '../pages/Dashboard/Dashboard';
import { MIDs } from '../pages/MIDs/MIDs';
import { MidEdit } from '../pages/MIDs/MidEdit';
import {
    ManagementUsers,
    EditMangementUser,
    CreateMangementUser
} from '../pages/Users/ManagementUsers';
import { Roles } from '../pages/Roles/Roles';
import { EditRole } from '../pages/Roles/EditRole';
import { PermissionType } from '../types/permissions';
import PageLeadForm from 'src/pages/Leads/PageLeadForm';
import { RdrsView } from 'src/pages/Rdrs/RdrsView';

export const LOGIN_ROUTE = {
    url: routes.login,
    title: 'Login',
    component: Login,
    permission: PermissionType.Login
};

export const CREATE_ACCOUNT_ROUTE = {
    url: routes.createAccount,
    title: 'Create Account',
    component: CreateAccount,
    permission: PermissionType.AccountEdit
};

export const CREATE_USER_ROUTE = {
    url: routes.createUser,
    title: 'Create user',
    component: CreateAccount,
    permission: PermissionType.UserEdit
};

export const USERS_ROUTE = {
    url: routes.usersView,
    title: 'Users',
    component: Users,
    permission: PermissionType.UsersView
};

export const SUB_AFFILIATES_ROUTE = {
    url: routes.agentsView,
    title: 'Agents',
    component: Agents,
    permission: PermissionType.AgentsView
};

export const CREATE_AGENT = {
    url: routes.agentsNew,
    title: 'Agent',
    component: AgentForm,
    permission: PermissionType.AgentEdit
};

export const EDIT_AGENT = {
    url: routes.agent(),
    title: 'Agent-Info',
    component: AgentForm,
    permission: PermissionType.AgentEdit
};

export const SERVICES_ROUTE = {
    url: routes.services,
    title: 'Services',
    component: Services,
    permission: PermissionType.ServicesView
};

export const SERVICE_EDIT_ROUTE = {
    url: routes.service(),
    title: 'Edit Service',
    component: ServiceEdit,
    permission: PermissionType.ServiceEdit
};

export const SERVICE_NEW_ROUTE = {
    url: routes.servicesNew,
    title: 'New Service',
    component: ServiceEdit,
    permission: PermissionType.ServiceEdit
};

export const CATEGORIES_ROUTE = {
    url: routes.categoriesView,
    title: 'Categories',
    component: Categories,
    permission: PermissionType.CategoriesView
};

export const CATEGORY_EDIT_ROUTE = {
    url: routes.category(),
    title: 'Edit Category',
    component: CategoryEdit,
    permission: PermissionType.CategoryEdit
};

export const CATEGORY_NEW_ROUTE = {
    url: routes.categoriesNew,
    title: 'New Category',
    component: CategoryEdit,
    permission: PermissionType.CategoryEdit
};

export const BILLING_MODELS_ROUTE = {
    url: routes.billingModelsView,
    title: 'Billing Models',
    component: BillingModels,
    permission: PermissionType.BillingModelsView
};

export const BILLING_MODEL_NEW_ROUTE = {
    url: routes.billingModelsNew,
    title: 'New Billing Model',
    component: BillingModelEdit,
    permission: PermissionType.BillingModelEdit
};

export const BILLING_MODEL_EDIT_ROUTE = {
    url: routes.billingModel(),
    title: 'Edit Billing Model',
    component: BillingModelEdit,
    permission: PermissionType.BillingModelEdit
};

export const RDRS_ROUTE = {
    url: routes.rdrsView,
    title: 'Edit RDRs',
    component: RdrsView,
    permission: PermissionType.BillingModelEdit
};

export const ACCOUNTS_ROUTE = {
    url: routes.accountsView,
    title: 'Accounts',
    component: Accounts,
    permission: PermissionType.AccountsView
};

export const PARTNER_EDIT_ROUTE = {
    url: routes.partner(),
    title: 'Edit Partner',
    component: PartnerEdit,
    permission: PermissionType.PartnerEdit
};

export const PARTNER_NEW_ROUTE = {
    url: routes.partnersNew,
    title: 'New Partner',
    component: PartnerEdit,
    permission: PermissionType.PartnerEdit
};

export const MERCHANT_EDIT_ROUTE = {
    url: routes.merchant(),
    title: 'Edit Merchant',
    component: MerchantEdit,
    permission: PermissionType.MerchantEdit
};

export const MERCHANT_NEW_ROUTE = {
    url: routes.merchantsNew,
    title: 'New Merchant',
    component: MerchantEdit,
    permission: PermissionType.MerchantEdit
};

export const ORGANIZATIONS_ROUTE = {
    url: routes.organizations,
    title: 'Organizations',
    component: Organizations,
    permission: PermissionType.OrganizationsView
};

export const ORGANIZATION_NEW_ROUTE = {
    url: routes.organizationNew,
    title: 'Create Organization',
    component: CreateOrganization,
    permission: PermissionType.OrganizationEdit
};

export const MIDS_ROUTE = {
    url: routes.midsView,
    title: 'MIDs',
    component: MIDs,
    permission: PermissionType.MidsView
};

export const MID_EDIT_ROUTE = {
    url: routes.mid(),
    title: 'Edit MID',
    component: MidEdit,
    permission: PermissionType.MidEdit
};

export const MID_NEW_ROUTE = {
    url: routes.midNew,
    title: 'New MID',
    component: MidEdit,
    permission: PermissionType.MidEdit
};

export const DASHBOARD_ROUTE = {
    url: routes.dashboard,
    title: 'Dashboard',
    component: Dashboard,
    permission: PermissionType.Dashboard
};

export const MANAGEMENT_USERS_ROUTE = {
    url: routes.managementUsers,
    title: 'Management Users',
    component: ManagementUsers,
    permission: PermissionType.UsersView
};

export const EDIT_MANAGEMENT_USERS_ROUTE = {
    url: routes.managementUser(),
    title: 'Edit Management Users',
    component: EditMangementUser,
    permission: PermissionType.UserEdit
};

export const NEW_MANAGEMENT_USERS_ROUTE = {
    url: routes.managementUserNew,
    title: 'Create Management Users',
    component: CreateMangementUser,
    permission: PermissionType.UserEdit
};

export const PERMISSIONS_ROLES_ROUTE = {
    url: routes.permissionsRoles,
    title: 'Roles',
    component: Roles,
    permission: PermissionType.RolesView
};

export const EDIT_PERMISSIONS_ROLE_ROUTE = {
    url: routes.permissionsRole(),
    title: 'Edit Role',
    component: EditRole,
    permission: PermissionType.RoleEdit
};

export const NEW_PERMISSIONS_ROLE_ROUTE = {
    url: routes.permissionsRoleNew,
    title: 'New Role',
    component: EditRole,
    permission: PermissionType.RoleEdit
};

export const NEW_LEAD_ROUTE = {
    url: routes.leadsNew,
    title: 'New Lead',
    component: PageLeadForm,
    permission: PermissionType.RoleEdit
};

export const EDIT_LEAD_ROUTE = {
    url: routes.lead(),
    title: 'Edit Lead',
    component: PageLeadForm,
    permission: PermissionType.RoleEdit
};

export const AUTH_ROUTES = [LOGIN_ROUTE, CREATE_ACCOUNT_ROUTE];

/*
    routes with 'new' should be higher edit
    For example

    (correct):
    PARTNER_NEW_ROUTE,
    PARTNER_EDIT_ROUTE,

    (incorrect):
    PARTNER_EDIT_ROUTE,
    PARTNER_NEW_ROUTE,
 */
export const ROUTES = [
    NEW_PERMISSIONS_ROLE_ROUTE,
    EDIT_PERMISSIONS_ROLE_ROUTE,
    PERMISSIONS_ROLES_ROUTE,

    NEW_MANAGEMENT_USERS_ROUTE,
    EDIT_MANAGEMENT_USERS_ROUTE,
    MANAGEMENT_USERS_ROUTE,

    USERS_ROUTE,
    CREATE_USER_ROUTE,

    SUB_AFFILIATES_ROUTE,

    CREATE_AGENT,
    EDIT_AGENT,

    SERVICE_NEW_ROUTE,
    SERVICES_ROUTE,
    SERVICE_EDIT_ROUTE,

    CATEGORIES_ROUTE,
    CATEGORY_NEW_ROUTE,
    CATEGORY_EDIT_ROUTE,

    BILLING_MODELS_ROUTE,
    BILLING_MODEL_NEW_ROUTE,
    BILLING_MODEL_EDIT_ROUTE,

    RDRS_ROUTE,

    ACCOUNTS_ROUTE,

    PARTNER_EDIT_ROUTE,
    PARTNER_NEW_ROUTE,

    MERCHANT_NEW_ROUTE,
    MERCHANT_EDIT_ROUTE,

    ORGANIZATION_NEW_ROUTE,
    ORGANIZATIONS_ROUTE,

    MIDS_ROUTE,
    MID_EDIT_ROUTE,
    MID_NEW_ROUTE,

    DASHBOARD_ROUTE,

    NEW_LEAD_ROUTE,
    EDIT_LEAD_ROUTE
];
