import React, { FC, memo, useEffect } from 'react';
import { AutocompleteOption, FormCard } from '../../Form';
import { Control } from 'react-hook-form';
import { FormFieldType, MerchantCreateOrUpdate } from '../../../types';
import { timezones } from '../../../constants';
import { useFieldsOptions } from '../../../utils/mids';

interface Props {
    control: Control<MerchantCreateOrUpdate, object>;
    partnersOptions: AutocompleteOption[];
    agentsOptions: AutocompleteOption[];
}

// names are temporary because back is not ready yet
const fields = (
    partners?: AutocompleteOption[],
    agents?: AutocompleteOption[]
) => [
    {
        name: 'partner.id',
        required: true,
        label: 'Partner',
        type: FormFieldType.Autocomplete,
        options: partners || [],
        xs: 4
    },
    {
        name: 'agent.id',
        label: 'Agent',
        type: FormFieldType.Autocomplete,
        options: agents || [],
        xs: 4
    }
];

const MerchantPartnersForm: FC<Props> = ({
    control,
    partnersOptions,
    agentsOptions
}) => {
    return (
        <FormCard
            control={control}
            fields={fields(partnersOptions, agentsOptions)}
            title="Partners"
        />
    );
};

export default memo(MerchantPartnersForm);
