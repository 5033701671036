import { AppState } from '../reducers';

export const getAgents = ({ agents }: AppState) => agents;
export const getServices = ({ services }: AppState) => services;
export const getMessage = ({ message }: AppState) => message;
export const getShortCategories = ({ shortCategories }: AppState) =>
    shortCategories;
export const getCategories = ({ categories }: AppState) => categories;
export const getBillingModels = ({ billingModels }: AppState) => billingModels;
export const getAuth = ({ auth }: AppState) => auth;
export const getUserPermissions = ({ permissions }: AppState) => permissions;
export const getUserRoutes = ({ routes }: AppState) => routes;
export const getMids = ({ mids }: AppState) => mids;
export const getRdrAlerts = ({ rdrAlerts }: AppState) => rdrAlerts;
