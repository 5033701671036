import { FC, memo } from 'react';
import { Control, useWatch } from 'react-hook-form';
import { TextFieldProps } from '@mui/material';
import { FormFieldType, MerchantCreateOrUpdate } from 'src/types';
import { Field, FormCard } from '../../Form/FormCard';
import { countries, languages, states, timezones } from 'src/constants';

interface Props {
    control: Control<MerchantCreateOrUpdate, object>;
}

const fields = (
    country: string
): (Field<MerchantCreateOrUpdate> & TextFieldProps)[] => [
    {
        name: 'name',
        label: 'Company name',
        required: true,
        xs: 6
    },
    {
        name: 'mid',
        label: 'MID',
        xs: 6
    },
    {
        name: 'dba',
        label: 'DBA',
        xs: 6
    },
    {
        name: 'mcc',
        label: 'MCC',
        xs: 6
    },
    {
        name: 'externalIdentifier',
        label: 'External Identifier',
        xs: 6
    },
    {
        name: 'accountInfo.country',
        label: 'Country',
        type: FormFieldType.Autocomplete,
        options: countries,
        required: true,
        inputProps: {
            maxLength: 2
        },
        xs: 6
    },
    {
        name: 'accountInfo.address',
        label: 'Address 1',
        required: true,
        xs: 6
    },
    {
        name: 'accountInfo.address2',
        label: 'Address 2',
        xs: 6
    },
    {
        name: 'accountInfo.city',
        label: 'City',
        required: true,
        inputProps: {
            maxLength: 50
        },
        xs: 4
    },
    {
        name: 'accountInfo.state',
        label: 'State',
        required: true,
        inputProps: {
            maxLength: 40
        },
        type: states[country] ? FormFieldType.Autocomplete : undefined,
        options: states[country],
        xs: 4
    },
    {
        name: 'accountInfo.zip',
        label: 'Postal Code',
        required: true,
        inputProps: {
            maxLength: 16
        },
        xs: 4
    },
    {
        name: 'webSite',
        label: 'Website',
        xs: 6
    },
    {
        name: 'timezone',
        label: 'Timezone',
        type: FormFieldType.Autocomplete,
        options: timezones,
        xs: 6
    }
];

const MerchantInfoForm: FC<Props> = ({ control }) => {
    const country = useWatch({ control, name: 'accountInfo.country' });

    return (
        <FormCard
            control={control}
            fields={fields(country)}
            title="Company Information"
        />
    );
};

export default memo(MerchantInfoForm);
