import { ComponentProps, CSSProperties } from 'react';
import {
    InputBaseComponentProps,
    TextField as MuiTextField,
    TextFieldProps,
    Autocomplete,
    Popper,
    Button,
    Box
} from '@mui/material';
import { styled } from '@mui/system';
import { Control, Controller, Path, ValidationRule } from 'react-hook-form';

const TextField = styled(MuiTextField)(`
    .MuiFormHelperText: {
        position: absolute;
        bottom: -20px;
    }
`);

export interface AutocompleteOption {
    label: string;
    value: string;
}

interface Props<T = any> {
    name: Path<T>;
    options: AutocompleteOption[];
    label?: string;
    control: Control<T, object>;
    required?: boolean;
    pattern?: ValidationRule<RegExp>;
    inputProps?: InputBaseComponentProps;
    placeholder?: string;
    textFieldProps?: TextFieldProps;
    autocompleteProps?: Partial<ComponentProps<typeof Autocomplete>>;
    buttonProps?: Partial<ComponentProps<typeof Button>>;
    style?: CSSProperties;
    freeSolo?: boolean;
}

export type FormAutoCompleteTypeProps<T = any> = Props<T>;

type FormAutoCompleteType = <T = any>(
    props: FormAutoCompleteTypeProps<T>
) => JSX.Element;

const PlusButton = (props: ComponentProps<typeof Button>) => (
    <Box
        sx={{
            alignSelf: 'stretch',
            display: 'flex',
            alignItems: 'flex-end',
            marginLeft: '10px'
        }}
    >
        <Button
            {...props}
            variant="contained"
            sx={{ height: '30px', width: '30px', minWidth: '30px' }}
            // @ts-ignore
        >
            +
        </Button>
    </Box>
);

export const FormAutoComplete: FormAutoCompleteType = ({
    name,
    control,
    label,
    required = false,
    pattern,
    inputProps,
    textFieldProps = {},
    autocompleteProps,
    options,
    placeholder,
    style,
    freeSolo,
    buttonProps,
    ...otherProps
}) => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
        <Controller
            name={name}
            control={control}
            rules={{ required, pattern }}
            render={({ field: { onChange, value }, fieldState }) => {
                let valueToPaste;

                if (Array.isArray(value)) {
                    // For multiple
                    valueToPaste = value;
                } else {
                    valueToPaste =
                        options.find(
                            ({ value: optValue }) => value === optValue
                        ) || (freeSolo ? value : '');
                }

                return (
                    <Autocomplete
                        disablePortal
                        options={options}
                        onChange={(e, data) => {
                            onChange(
                                (data as AutocompleteOption)?.value ||
                                    data ||
                                    ''
                            );
                        }}
                        value={valueToPaste}
                        renderOption={(props, option) => (
                            <li
                                {...props}
                                key={(option as AutocompleteOption).value}
                            >
                                {(option as AutocompleteOption).label}
                            </li>
                        )}
                        freeSolo={freeSolo}
                        sx={{ width: '100%' }}
                        style={style}
                        forcePopupIcon
                        PopperComponent={props => (
                            <Popper
                                {...props}
                                modifiers={[
                                    {
                                        name: 'flip'
                                        // enabled: false
                                    }
                                ]}
                            />
                        )}
                        {...autocompleteProps}
                        renderInput={params => (
                            <TextField
                                label={label}
                                placeholder={placeholder}
                                {...params}
                                {...textFieldProps}
                                variant="standard"
                                required={required}
                                error={fieldState.invalid}
                                helperText={fieldState.error?.message}
                                onChange={
                                    freeSolo
                                        ? e => onChange(e.target.value)
                                        : undefined
                                }
                                inputProps={{
                                    ...params.inputProps,
                                    'aria-autocomplete': 'none'
                                }}
                            />
                        )}
                    />
                );
            }}
        />
        {buttonProps && <PlusButton {...buttonProps} />}
    </div>
);
