import { routes } from './routes';
import CategoryIcon from '@mui/icons-material/Category';
import GroupIcon from '@mui/icons-material/Group';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import AddShoppingCartOutlinedIcon from '@mui/icons-material/AddShoppingCartOutlined';
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined';
import RefreshOutlined from '@mui/icons-material/RefreshOutlined';
import AccountBoxOutlinedIcon from '@mui/icons-material/AccountBoxOutlined';
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import { PermissionType } from '../types/permissions';

export const menuItems = [
    {
        url: routes.accountsView,
        title: 'Accounts',
        Icon: AccountBalanceOutlinedIcon,
        permission: PermissionType.AccountsView
    },
    {
        url: routes.services,
        title: 'Services',
        Icon: AddShoppingCartOutlinedIcon,
        permission: PermissionType.ServicesView
    },
    {
        url: routes.categoriesView,
        title: 'Categories',
        Icon: CategoryIcon,
        permission: PermissionType.CategoriesView
    },
    {
        url: routes.billingModelsView,
        title: 'Billing Models',
        Icon: CreditCardOutlinedIcon,
        permission: PermissionType.BillingModelsView
    },
    {
        url: routes.rdrsView,
        title: 'RDRs',
        Icon: RefreshOutlined,
        permission: PermissionType.BillingModelsView
    },
    {
        url: routes.midsView,
        title: 'MIDs',
        Icon: AccountBoxOutlinedIcon,
        permission: PermissionType.MidsView,
        startGroup: true
    },
    {
        url: routes.managementUsers,
        title: 'Users Management',
        Icon: ManageAccountsOutlinedIcon,
        permission: PermissionType.UsersView
    },
    {
        url: routes.permissionsRoles,
        title: 'Roles',
        Icon: BadgeOutlinedIcon,
        permission: PermissionType.RolesView
    }
];
