import { Button, Stack, Typography } from '@mui/material';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useHistory } from 'react-router';

import {
    useAgent,
    useAgents,
    useBillingModels,
    useServices
} from 'src/api/hooks';
import { CommissionPaymentInfoForm } from 'src/components/Agents/Forms/CommissionPaymentInfoForm';
import { SupportContactsForm } from 'src/components/Agents/Forms/SupportContactsForm';
import { CompanyContactForm } from 'src/components/Agents/Forms/CompanyContactForm';
import { AgentInfoForm } from 'src/components/Agents/Forms/AgentInfoForm';
import { routes } from 'src/constants';
import { IAgentsApiPostPut, ServiceDto } from 'src/types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Spin } from 'src/components/Spin/Spin';
import { LoadingButton } from '@mui/lab';
import agentValidationYupResolver from 'src/pages/Agents/forms/agentFormValidation';
import LeaveFormModal from 'src/components/Modal/LeaveFormModal';
import useBlockRouting from 'src/hooks/useBlockRouting';
import { usePartnersAsOptions } from 'src/hooks/partners/usePartnersAsOptions';
import useEditFormId from 'src/api/hooks/useEditFormId';
import StatusOfPaymentsForm from 'src/components/Agents/Forms/StatusOfPaymentsForm';
import StatusCard from 'src/components/Cards/StatusCard';
import { formatAgentForForm } from 'src/utils/formatters';
import ControlledNotesCard from 'src/components/Cards/NotesCard/ControlledNotesCard';
import agentFormInitialValues from 'src/pages/Agents/forms/agentFormInitialValues';
import AgreementsCard from 'src/components/Cards/AgreementsCard/AgreementsCard';
import { DevTool } from '@hookform/devtools';
import { logFormState } from 'src/utils/loggers';
import newAgent from '../../../api/mocks/newAgent';
import ServicesCard from 'src/components/Partners/ServicesCard';
import { generateFields, tableFields } from '../servicesFields';
import AgentPartnersForm from './AgentPartnersForm';
import { useFieldsOptions } from 'src/utils/mids';
import { useServicesForPartner } from 'src/hooks/partners/useServicesForPartner';

export const AgentForm = () => {
    const history = useHistory();
    const [isEdit, id] = useEditFormId();

    const { createAgent, updateAgent } = useAgents();

    const { fetchAgent, agent, loading: agentLoading } = useAgent();
    const { partnersAsOptions, loading: partnersLoading } =
        usePartnersAsOptions();

    const [buttonLoading, setButtonLoading] = useState(false);
    const [isModalToGoOutOpen, setIsModalToGoOutOpen] = useState(false);

    const { unblockAndGo, setCanGoOut } = useBlockRouting(() =>
        setIsModalToGoOutOpen(true)
    );

    const { fetchServices, services, loading: loadingServices } = useServices();

    const { fetchBillingModels, billingModels } = useBillingModels();

    const { partnersOptions } = useFieldsOptions();

    useEffect(() => {
        fetchBillingModels();

        fetchServices({
            pageSize: '1000'
        });
    }, []);

    const fieldsToDisplay = useMemo(
        () => generateFields(services, billingModels),
        [services, billingModels]
    );

    useEffect(() => {
        if (id) {
            fetchAgent(id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    const {
        handleSubmit,
        control,
        reset,
        formState,
        getValues,
        setValue,
        watch
    } = useForm<IAgentsApiPostPut>({
        defaultValues: agentFormInitialValues /* newAgent */,
        resolver: agentValidationYupResolver()
    });

    const partner = watch('partner');

    const {
        disableAddService,
        loadingServicesForPartner,
        messageForDisabling
    } = useServicesForPartner({ partnerId: partner?.id || '', services });

    useEffect(() => {
        setCanGoOut(!formState.isDirty);
    }, [formState]);

    useEffect(() => {
        if (agent && id && agent.id === id) {
            reset(formatAgentForForm(agent));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [agent]);

    const onSubmit: SubmitHandler<IAgentsApiPostPut> = data => {
        // we need to send null to server
        const agent = { ...data, partner: { id: data.partner?.id || null } };
        setButtonLoading(true);

        function handleUnblockAndCancel() {
            unblockAndGo(routes.accountsView);
        }

        if (isEdit && id) {
            updateAgent(id, agent, () => handleUnblockAndCancel()).finally(() =>
                setButtonLoading(false)
            );
        } else {
            createAgent(agent, () => handleUnblockAndCancel()).finally(() =>
                setButtonLoading(false)
            );
        }
    };

    const handleCancel = () => history.push(routes.accountsView);

    const onSelectServiceHandler = useCallback((serviceId: ServiceDto) => {
        // console.log('service onSelect', service);
        if (!serviceId) return;

        // setValue(`services.${serviceId}.cost`, services[serviceId].cost);
        // setValue('')
    }, []);

    logFormState(getValues, formState);

    return (
        <Spin loading={agentLoading || partnersLoading}>
            <Stack spacing={2} flexGrow={1} mb={3}>
                <Typography variant="h5">
                    {isEdit ? 'Edit agent' : 'Create agent'}
                </Typography>
                <form>
                    <Stack spacing={2} alignItems="end">
                        <AgentInfoForm
                            control={control}
                            partnersAsOptions={partnersAsOptions}
                        />

                        <AgentPartnersForm
                            control={control}
                            partnersOptions={partnersOptions}
                        />

                        <ServicesCard
                            modalTitle="Add service to Agent"
                            tableFields={tableFields}
                            modalInputs={fieldsToDisplay}
                            onServiceSelectModal={onSelectServiceHandler}
                            control={control}
                            loading={
                                loadingServices || loadingServicesForPartner
                            }
                            disableAddService={disableAddService}
                            messageForDisabling={messageForDisabling}
                        />
                        <CompanyContactForm control={control} />
                        <SupportContactsForm control={control} />
                        <AgreementsCard control={control} setValue={setValue} />
                        <CommissionPaymentInfoForm control={control} />
                        <StatusOfPaymentsForm control={control} />
                        {/*<PrimaryUsernameCard control={control} />*/}
                        <StatusCard control={control} keyWord="Agent" />
                        <ControlledNotesCard name="notes" control={control} />
                        <Stack direction="row" spacing={2}>
                            <Button
                                onClick={handleCancel}
                                variant="outlined"
                                disabled={buttonLoading}
                            >
                                Cancel
                            </Button>
                            <LoadingButton
                                onClick={handleSubmit(onSubmit)}
                                loading={buttonLoading}
                                variant="contained"
                            >
                                Save
                            </LoadingButton>
                        </Stack>
                    </Stack>
                </form>
            </Stack>
            <LeaveFormModal
                open={isModalToGoOutOpen}
                onClose={() => setIsModalToGoOutOpen(false)}
                onLeave={() => unblockAndGo()}
                onSave={() => console.log('Save Draft')}
            />
            {/*<DevTool control={control} />*/}
        </Spin>
    );
};
