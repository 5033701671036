import { Box, Card, CardContent, Stack, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { FormInputText } from 'src/components/Form';
import { Link, useHistory } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import { useState } from 'react';
import { useAuth } from 'src/api/hooks';
import { routes } from 'src/constants';
import { getPartnerKey } from 'src/utils/getGlobalPartnerKey';
import PartnerLogo from 'src/components/PartnerLogo/PartnerLogo';

export const Login = () => {
    const { control, handleSubmit } = useForm();
    const { setIsLoggedIn, fetchSignIn } = useAuth();
    const [buttonLoading, setButtonLoading] = useState(false);
    const history = useHistory();

    const onSubmit = (data: any) => {
        setButtonLoading(true);
        fetchSignIn({
            email: data.email,
            password: data.password,
            applicationId: 'BillingModule',
            rememberMe: true
        })
            .then(userData => {
                setIsLoggedIn(true);
                setButtonLoading(false);
                if (userData.organizations && userData.organizations.length) {
                    history.push(routes.organizations);
                } else {
                    history.push(routes.organizationNew);
                }
            })
            .catch(() => {
                setButtonLoading(false);
            });
    };

    return (
        <Stack
            alignItems="center"
            justifyContent="center"
            style={{ height: '80vh' }}
        >
            <Box mb="15px">
                <PartnerLogo />
            </Box>
            <Card sx={{ width: '480px' }}>
                <CardContent>
                    <Typography variant="h6" component="div">
                        Log In
                    </Typography>
                    <form>
                        <FormInputText
                            name="email"
                            label="Email"
                            control={control}
                            required
                            InputLabelProps={{ required: false }}
                            style={{ width: '100%', marginBottom: '10px' }}
                        />
                        <FormInputText
                            name="password"
                            label="Password"
                            type="password"
                            control={control}
                            required
                            InputLabelProps={{ required: false }}
                            style={{ width: '100%', marginBottom: '20px' }}
                        />
                        <LoadingButton
                            loading={buttonLoading}
                            variant="contained"
                            type="submit"
                            style={{ width: '100%', marginBottom: '20px' }}
                            onClick={handleSubmit(onSubmit)}
                        >
                            LOG IN
                        </LoadingButton>
                        <Stack direction="row" justifyContent={'space-between'}>
                            <Link to={routes.createAccount}>
                                Create account
                            </Link>
                            <Link to={''}>Forgot password?</Link>
                        </Stack>
                    </form>
                </CardContent>
            </Card>
        </Stack>
    );
};
