import { FC } from 'react';
import { Control } from 'react-hook-form';
import { Stack } from '@mui/material';

import { FormFieldType, UserInfoDto } from 'src/types';
import { Spin } from 'src/components/Spin/Spin';
import { countries, VALIDATION_MSG } from 'src/constants';
import { Field, FormCard } from '../../Form';
import { RolesCard } from './RolesCard';

interface Props {
    control: Control<UserInfoDto, object>;
    loading?: boolean;
    setEditRolesMode: () => void;
    roles: string[];
}

const fields: Field<UserInfoDto>[] = [
    {
        name: 'firstName',
        label: 'First Name',
        required: true,
        inputProps: {
            maxLength: 100
        },
        gridProps: {
            md: 6
        }
    },
    {
        xs: 6
    },
    {
        name: 'lastName',
        label: 'Last Name',
        required: true,
        inputProps: {
            maxLength: 100
        },
        gridProps: {
            md: 6
        }
    },
    {
        xs: 6
    },
    {
        name: 'email',
        label: 'Email',
        required: true,
        pattern: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,}$/i,
            message: VALIDATION_MSG.EMAIL
        },
        gridProps: {
            md: 6
        }
    },
    {
        xs: 6
    },
    {
        name: 'phoneNumber',
        label: 'Phone Number',
        required: true,
        type: FormFieldType.Phone,
        inputProps: {
            maxLength: 16
        },
        gridProps: {
            md: 6
        }
    },
    {
        xs: 6
    },
    {
        name: 'country',
        label: 'Country',
        type: FormFieldType.Autocomplete,
        options: countries,
        required: true,
        gridProps: {
            md: 6
        }
    }
];

export const UserEditFormFields: FC<Props> = ({
    control,
    loading,
    setEditRolesMode,
    roles
}) => {
    const formFields = fields;

    return (
        <Spin loading={loading}>
            <Stack spacing={2} sx={{ width: '100%' }}>
                <FormCard
                    control={control}
                    fields={formFields}
                    cardStyle={{ paddingBottom: 40 }}
                />
                <RolesCard roles={roles} setEditRolesMode={setEditRolesMode} />
            </Stack>
        </Spin>
    );
};
