import { useEffect } from 'react';
import { useAgents, useBanks, useMerchants, usePartners } from 'src/api/hooks';
import { useProcessors } from 'src/api/hooks/useProcessors';
import {
    IMIDBankDto,
    ICreateOrUpdateMID,
    IMIDEditDto,
    IMIDProcessorsDto
} from 'src/types';
import { AutocompleteOption } from '../components/Form';

type ValuesType = { id?: string | null; name?: string | null }[];

const getOptions = (values: ValuesType): AutocompleteOption[] =>
    values.map(({ id, name }) => ({
        value: id || '',
        label: name || ''
    }));

export const useFieldsOptions = () => {
    const { partners } = usePartners();
    const { merchants } = useMerchants();
    const { banks } = useBanks();
    const { processors } = useProcessors();
    const { agents, fetchAgents } = useAgents();

    const partnersOptions = getOptions(partners);
    const merchantsOptions = getOptions(merchants);
    const banksOptions = getOptions(banks);
    const processorsOptions = getOptions(processors);
    const agentsOptions = getOptions(Object.values(agents));

    useEffect(() => {
        void fetchAgents();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        partnersOptions,
        merchantsOptions,
        agentsOptions,
        banksOptions,
        processorsOptions,
        banks,
        processors
    };
};
