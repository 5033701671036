import dayjs from 'dayjs';
import { bankingRoutingRegex } from 'src/constants/regex';
import { array, mixed, number, object, setLocale, string } from 'yup';

setLocale({
    mixed: {
        required: 'Required'
    }
});

export const bankRoutingValidation = string()
    .matches(bankingRoutingRegex, {
        excludeEmptyString: true,
        message: 'The Routing number must be 8-9 digits long'
    })
    .required('Is Required')
    .ensure();

export const integerAndFloatValidation = number()
    .min(0, 'Must be greater than 0')
    .typeError('Must be a number');

export const partnerStatusValidation = {
    status: mixed()
        .oneOf(
            ['Pending', 'Active', 'Collections', 'Notes', 'Deactivated'],
            'Must be one of variants'
        )
        .optional(),
    activateAt: string().nullable(),
    deactivateAt: string()
        .nullable()
        .test(
            'activateAt',
            'The deactivation date must be greater than the activation date',
            (deactivateAt, context) => {
                const { activateAt } = context.parent;

                if (!activateAt || !deactivateAt) return true;

                if (activateAt)
                    return (
                        dayjs(activateAt).valueOf() <
                        dayjs(deactivateAt).valueOf()
                    );

                return false;
            }
        )
};

export const notesValidation = {
    notes: array(
        object({
            id: string().nullable(),
            note: string().nullable(),
            createdBy: string().nullable(),
            created: string()
        })
    )
        .optional()
        .nullable()
};

export const tokensValidation = {
    tokens: array(
        object({
            token: string().nullable()
        })
    )
};

export const servicesValidation = {
    services: array(mixed())
};

export const emailValidation = string()
    .email('Must be a valid email')
    .max(100)
    .ensure();

export const supportAndAccountingContactValidation = {
    accountingSupportContact: object({
        emailAddress: emailValidation.required(),
        phoneNumber: string().required().max(15).ensure()
    }),
    supportContact: object({
        emailAddress: emailValidation,
        phoneNumber: string().max(15).ensure()
    })
};

// SchemaOf works!
// Example!

// const shema: SchemaOf<{ name?: string}> = object({
//     name: string()
// });
