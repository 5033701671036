import React from 'react';
import { MenuItem, Stack, Typography } from '@mui/material';
import {
    OrganizationId,
    OrganizationName
} from 'src/components/OrganizationSelect/styles';
import { IOrganizationDto } from 'src/types';
import { OnSelectType } from 'src/components/OrganizationSelect/OrganizationSelect';

interface MenuItemsProps {
    onSelect: OnSelectType;
    organizations: IOrganizationDto[];
    isLoading: boolean;
}

const MenuItems = ({ onSelect, organizations, isLoading }: MenuItemsProps) => {
    return (
        <>
            {!isLoading && organizations.length === 0 && (
                <Typography variant="subtitle1" fontStyle="italic" p={2}>
                    No organizations
                </Typography>
            )}

            {organizations.map(({ id, name }, index) => (
                <MenuItem
                    key={id + name + index}
                    onClick={() => onSelect({ id, name })}
                >
                    <Stack>
                        <OrganizationName>{name}</OrganizationName>
                        <OrganizationId>{id}</OrganizationId>
                    </Stack>
                </MenuItem>
            ))}
        </>
    );
};

export default MenuItems;
