import { Button, Chip } from '@mui/material';
import { FC } from 'react';
import { useHistory } from 'react-router';
import { routes } from 'src/constants';

import { ColumnType, UserDto } from 'src/types';
import { Table } from '../Table';
import { ActionsTableButton } from '../TablePage/ActionsTableButton';

interface Props {
    data: UserDto[];
    loading?: boolean;
}

export const UsersTable: FC<Props> = ({ data, loading }) => {
    const history = useHistory();

    const columns: ColumnType<UserDto>[] = [
        {
            title: 'ID',
            dataIndex: 'id'
        },
        {
            title: 'First name',
            dataIndex: 'firstName'
        },
        {
            title: 'Last name',
            dataIndex: 'lastName'
        },
        {
            title: 'Account status',
            dataIndex: 'status',
            render: value => <Chip label={value} />
        },
        {
            title: '',
            dataIndex: 'actions',
            align: 'center',
            width: '60px',
            render: (_, { id }) => (
                <ActionsTableButton
                    menuItems={[
                        {
                            title: 'Edit',
                            onClick: () =>
                                history.push(routes.managementUser(id))
                        }
                    ]}
                />
            ),
            mobile: {
                render: (_data, { id }) => (
                    <Button
                        fullWidth
                        variant="contained"
                        onClick={() => history.push(routes.managementUser(id))}
                    >
                        Edit
                    </Button>
                ),
                fullWidth: true
            }
        }
    ];

    return (
        <Table
            columns={columns}
            data={data}
            loading={loading}
            mobile={{
                renderSummary: row => `${row.firstName} ${row.lastName}`
            }}
        />
    );
};
