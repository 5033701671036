import { FC } from 'react';
import { Box, Button, IconButton, Toolbar } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useLocation } from 'react-router';
import { useAuth } from 'src/api/hooks';
import { OrganizationSelect } from '../OrganizationSelect';
import { AppBar } from './styles';
import { routes } from '../../constants';

interface Props {
    toggleDrawer?: () => void;
    open?: boolean;
    showLogOutButton?: boolean;
    showNavbar?: boolean;
}

export const Header: FC<Props> = ({
    toggleDrawer,
    open,
    showLogOutButton,
    showNavbar = true
}) => {
    const {
        logOut,
        setSelectedOrganization,
        organizations,
        selectedOrganization
    } = useAuth();

    //TODO: Make it more properly
    const location = useLocation();
    const isOrganizationRoute = location.pathname === routes.organizations;

    return (
        <AppBar open={open}>
            <Toolbar>
                {!!toggleDrawer && !showNavbar && (
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={toggleDrawer}
                        sx={{
                            marginRight: '36px',
                            ...(open && { display: 'none' })
                        }}
                    >
                        <MenuIcon />
                    </IconButton>
                )}
                {!isOrganizationRoute &&
                    !!organizations?.length &&
                    !!setSelectedOrganization && (
                        <Box flex={1}>
                            <OrganizationSelect
                                selectedOrganization={selectedOrganization}
                                onSelect={setSelectedOrganization}
                            />
                        </Box>
                    )}
                {showLogOutButton && (
                    <Button
                        onClick={logOut}
                        variant="contained"
                        sx={{ marginLeft: 'auto' }}
                    >
                        Log out
                    </Button>
                )}
            </Toolbar>
        </AppBar>
    );
};
