import { Button, Stack, Typography } from '@mui/material';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useHistory } from 'react-router';

import { LoadingButton } from '@mui/lab';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useBillingModels, useMerchant, useServices } from 'src/api/hooks';
import useEditFormId from 'src/api/hooks/useEditFormId';
import StatusCard from 'src/components/Cards/StatusCard';
import CommissionPaymentInfoForm from 'src/components/Merchants/Forms/CommissionPaymentInfoForm';
import CompanyContactForm from 'src/components/Merchants/Forms/CompanyContactForm';
import MerchantInfoForm from 'src/components/Merchants/Forms/MerchantInfoForm';
import LeaveFormModal from 'src/components/Modal/LeaveFormModal';
import { Spin } from 'src/components/Spin/Spin';
import { routes } from 'src/constants';
import useBlockRouting from 'src/hooks/useBlockRouting';
import merchantValidationYupResolver from 'src/pages/Merchants/merchantFormValidation';
import { MerchantCreateOrUpdate } from 'src/types';

import { debounce } from 'lodash';
import { useDispatch } from 'react-redux';
import ControlledNotesCard from 'src/components/Cards/NotesCard/ControlledNotesCard';
import { RDRCard } from 'src/components/Merchants/Forms/RDRCard';
import TokensCard from 'src/components/Merchants/Forms/TokensCard';
import { useServicesForPartner } from 'src/hooks/partners/useServicesForPartner';
import merchantFormInitialValues from 'src/pages/Merchants/merchantFormInitialValues';
import { formatMerchantForForm } from 'src/utils/formatters';
import { apiPutMerchantMid } from '../../api/endpoints/merchants';
import MerchantPartnersForm from '../../components/Merchants/Forms/MerchantPartnersForm';
import ServicesCard from '../../components/Partners/ServicesCard';
import useNonInitialEffect from '../../hooks/useNonInitialEffect';
import { showMessageAction } from '../../store/actions';
import { useFieldsOptions } from '../../utils/mids';
import { generateModalInputs, tableFields } from './servicesFields';

export const MerchantEdit = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [isEdit, id] = useEditFormId();

    const [buttonLoading, setButtonLoading] = useState(false);
    const [isModalToGoOutOpen, setIsModalToGoOutOpen] = useState(false);

    const { handleSubmit, control, reset, watch, formState, setError } =
        useForm<MerchantCreateOrUpdate>({
            defaultValues: merchantFormInitialValues,
            resolver: merchantValidationYupResolver()
        });

    const partner = watch('partner');
    const mid = watch('mid');

    const { unblockAndGo, setCanGoOut } = useBlockRouting(() =>
        setIsModalToGoOutOpen(true)
    );

    /*
        Check mid field for existing this MID in other merchants
     */
    const debouncedMIDChecking = useCallback(
        debounce(midName => {
            apiPutMerchantMid(id || null, midName)
                .then(({ message, success }) => {
                    if (!success) {
                        setError('mid', { message }, { shouldFocus: true });
                    }
                })
                .catch(err => {
                    dispatch(
                        showMessageAction({
                            type: 'error',
                            message: err.message
                        })
                    );
                });
        }, 500),
        [id]
    );

    const {
        fetchMerchant,
        merchant,
        loading: merchantLoading,
        createMerchant,
        updateMerchant
    } = useMerchant();

    const { partnersOptions, agentsOptions } = useFieldsOptions();

    const { fetchBillingModels, billingModels } = useBillingModels();

    useEffect(() => {
        fetchBillingModels();
    }, []);

    useEffect(() => {
        if (id) {
            fetchMerchant(id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    useNonInitialEffect(() => {
        if (mid) {
            debouncedMIDChecking(mid);
        }
    }, [mid]);

    useEffect(() => {
        setCanGoOut(!formState.isDirty);
    }, [formState]);

    useEffect(() => {
        if (merchant && id && merchant.id === id) {
            // @ts-ignore
            reset(formatMerchantForForm(merchant));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [merchant]);

    const { fetchServices, services, loading: loadingServices } = useServices();

    const {
        disableAddService,
        loadingServicesForPartner,
        messageForDisabling
    } = useServicesForPartner({ partnerId: partner.id || '', services });

    useEffect(() => {
        // We fetch all services
        void fetchServices({
            pageSize: '1000'
        });
    }, []);

    const onSubmit: SubmitHandler<MerchantCreateOrUpdate> = data => {
        setButtonLoading(true);

        function handleUnblockAndCancel() {
            unblockAndGo(routes.accountsView);
        }

        if (isEdit && id) {
            updateMerchant(id, data, () => handleUnblockAndCancel()).finally(
                () => setButtonLoading(false)
            );
        } else {
            createMerchant(data, () => handleUnblockAndCancel()).finally(() =>
                setButtonLoading(false)
            );
        }
    };

    const handleCancel = () => history.push(routes.accountsView);

    const fieldsToDisplay = useMemo(
        () => generateModalInputs(services, billingModels),
        [services, billingModels]
    );

    return (
        <Spin loading={merchantLoading}>
            <Stack spacing={2} flexGrow={1} mb={3}>
                <Typography variant="h5">
                    {isEdit ? 'Edit merchant' : 'Create merchant'}
                </Typography>
                <form>
                    <Stack spacing={2} alignItems="end">
                        <MerchantInfoForm control={control} />
                        <MerchantPartnersForm
                            control={control}
                            partnersOptions={partnersOptions}
                            agentsOptions={agentsOptions}
                        />
                        <ServicesCard
                            modalTitle="Add service to Merchant"
                            tableFields={tableFields}
                            modalInputs={fieldsToDisplay}
                            control={control}
                            loading={
                                loadingServices || loadingServicesForPartner
                            }
                            disableAddService={disableAddService}
                            messageForDisabling={messageForDisabling}
                        />
                        <CompanyContactForm control={control} />
                        <CommissionPaymentInfoForm control={control} />
                        {/*<PrimaryUsernameCard control={control} />*/}
                        <StatusCard control={control} keyWord="Merchant" />
                        <ControlledNotesCard name="notes" control={control} />
                        <TokensCard control={control} />
                        <RDRCard control={control} />
                        <Stack direction="row" spacing={2}>
                            <Button
                                onClick={handleCancel}
                                variant="outlined"
                                disabled={buttonLoading}
                            >
                                Cancel
                            </Button>
                            <LoadingButton
                                onClick={handleSubmit(onSubmit)}
                                variant="contained"
                                loading={buttonLoading}
                            >
                                {isEdit ? 'Save changes' : 'Continue'}
                            </LoadingButton>
                        </Stack>
                    </Stack>
                </form>
            </Stack>
            <LeaveFormModal
                open={isModalToGoOutOpen}
                onClose={() => setIsModalToGoOutOpen(false)}
                onLeave={() => unblockAndGo()}
                onSave={() => console.log('Save Draft')}
            />
        </Spin>
    );
};
