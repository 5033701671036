import { LoadingButton } from '@mui/lab';
import { Card, CardContent, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { apiPostOrganizations } from 'src/api/endpoints/organizations';
import { useAuth } from 'src/api/hooks';
import { FormInputText } from 'src/components/Form';
import { routes } from 'src/constants';
import { getAuth } from 'src/store/selectors';

export const CreateOrganization = () => {
    const [loading, setLoading] = useState(false);
    const { userData } = useSelector(getAuth);
    const { addUserOrganization, setSelectedOrganization } = useAuth();
    const { control, handleSubmit } = useForm();
    const history = useHistory();

    const handleCancel = () => {
        history.push(routes.dashboard);
    };

    const onSubmit = (data: any) => {
        const { name } = data;
        setLoading(true);
        apiPostOrganizations({
            name,
            userId: userData?.id || ''
        })
            .then(result => {
                setSelectedOrganization({
                    name: result.name,
                    id: result.id
                });
                return result.id;
            })
            .then(organizationId => {
                //TODO: refactor organization list update. Try to avoid caching organizations completely
                addUserOrganization({
                    name,
                    id: organizationId ?? name
                });
            })
            .then(() => {
                history.push(routes.dashboard);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <Stack
            alignItems="center"
            justifyContent="center"
            style={{ height: '80vh' }}
        >
            <Card sx={{ width: '480px' }}>
                <CardContent>
                    <Typography
                        variant="h6"
                        component="div"
                        style={{ marginBottom: 10 }}
                    >
                        Create an Organization
                    </Typography>
                    <form>
                        <Stack direction="row" spacing={1}>
                            <FormInputText
                                control={control}
                                name="name"
                                variant="standard"
                                size="small"
                                label="Organization Name"
                                required
                                sx={{ width: '100%', marginBottom: 4 }}
                            />
                        </Stack>
                        <LoadingButton
                            variant="contained"
                            loading={loading}
                            style={{
                                width: '45%',
                                marginBottom: '20px'
                            }}
                            onClick={handleSubmit(onSubmit)}
                        >
                            CREATE ORGANIZATION
                        </LoadingButton>
                        <LoadingButton
                            variant="contained"
                            loading={loading}
                            style={{
                                width: '45%',
                                marginBottom: '20px',
                                marginLeft: '15px'
                            }}
                            onClick={handleCancel}
                        >
                            CANCEL
                        </LoadingButton>
                    </form>
                </CardContent>
            </Card>
        </Stack>
    );
};
