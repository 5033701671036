import { useHistory, useParams } from 'react-router';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { Stack, Typography, Button, Tooltip } from '@mui/material';
import { UserEditFormFields } from 'src/components/Users/Forms/UserEditFormFields';
import { RolesSelect } from 'src/components/Users/RolesSelect';
import { useOrganizationRoles, useRoles, useUser } from 'src/api/hooks';
import {
    UserInfoDto,
    RoleType,
    RoleDto,
    RolesByOrganizationDto,
    IEmailConfirmationLinkResult
} from 'src/types';
import { routes } from 'src/constants';
import { apiGetLink } from 'src/api/endpoints/roles';

const INITIAL_VALUES = {
    email: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
    country: '',
    systemRoles: [],
    rolesByOrganizations: []
};

export const EditMangementUser = () => {
    const { id = '' } = useParams<{ id?: string }>();

    const isEdit = !!id;

    const [buttonLoading, setButtonLoading] = useState(false);
    const [linkLoading, setLinkLoading] = useState(false);
    const [link, setLink] = useState<string>('');
    const {
        fetchUser,
        user,
        loading: userLoading,
        updateUser,
        createUser
    } = useUser();
    const history = useHistory();

    const { roles, fetchRoles, loading: rolesLoading } = useRoles();
    const { organizationRoles, fetchOrganizationRoles } =
        useOrganizationRoles(id);

    useEffect(() => {
        if (id) {
            fetchUser(id);
            setLinkLoading(true);
            apiGetLink(id)
                .then(({ link }) => {
                    setLink(link as string);
                })
                .finally(() => {
                    setLinkLoading(false);
                });
        }
        fetchRoles();
        fetchOrganizationRoles();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    const { handleSubmit, control, reset, watch, setValue } =
        useForm<UserInfoDto>({
            defaultValues: isEdit ? { id, ...INITIAL_VALUES } : INITIAL_VALUES
        });

    const rolesByOrganizations = watch('rolesByOrganizations') || [];
    const systemRoles = watch('systemRoles') || [];

    const getRolesList = () => [
        ...systemRoles.map(({ name }) => name),
        ...rolesByOrganizations.reduce<string[]>(
            (prev, { roles, organizationName }) => [
                ...prev,
                ...(roles.map(({ name }) => `${name} (${organizationName})`) ||
                    [])
            ],
            []
        )
    ];

    useEffect(() => {
        if (user && id && user.id === id) {
            const {
                systemRoles = [],
                rolesByOrganizations = [],
                ...userInfo
            } = user || {};

            const newRolesByOrganizations = rolesByOrganizations.reduce<
                RolesByOrganizationDto[]
            >(
                (prev, { organizationId, organizationName, roles }) => [
                    ...prev,
                    {
                        organizationId,
                        organizationName,
                        roles
                    }
                ],
                []
            );
            reset({
                ...userInfo,
                systemRoles,
                rolesByOrganizations: newRolesByOrganizations
            });
        } else {
            reset(INITIAL_VALUES);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    const onSubmit: SubmitHandler<UserInfoDto> = data => {
        setButtonLoading(true);

        const submitData = {
            ...data,
            systemRoles: data.systemRoles.map(({ id }) => id),
            rolesByOrganizations: data.rolesByOrganizations.map(org => ({
                organizationId: org.organizationId,
                roleIds: org.roles.map(({ id }) => id)
            }))
        };

        if (id) {
            updateUser(id, submitData, () =>
                history.push(routes.managementUsers)
            ).finally(() => setButtonLoading(false));
        } else {
            createUser(submitData, () =>
                history.push(routes.managementUsers)
            ).finally(() => setButtonLoading(false));
        }
    };
    const [roleMode, setRoleMode] = useState(false);
    const handleSetEditRolesMode = () => setRoleMode(true);

    const handleCancel = () => history.push(routes.managementUsers);

    const handleSaveRoles = (
        systemRoles: RoleDto[],
        organizationRoles: RolesByOrganizationDto[]
    ) => {
        setValue('systemRoles', systemRoles);
        if (organizationRoles.length) {
            setValue('rolesByOrganizations', organizationRoles);
            setRoleMode(false);
        }
    };

    return (
        <Stack spacing={2} flexGrow={1} mb={3}>
            <Typography variant="h5">
                {isEdit ? 'Edit user' : 'Create user'}
            </Typography>
            {roleMode ? (
                <RolesSelect
                    user={user}
                    systemRoles={roles.filter(
                        ({ type }) => type !== RoleType.Custom
                    )}
                    organizationRoles={organizationRoles}
                    selectedRoles={systemRoles}
                    selectedOrganizationRoles={rolesByOrganizations}
                    loading={rolesLoading}
                    onSave={handleSaveRoles}
                    onCancel={() => setRoleMode(false)}
                />
            ) : (
                <form>
                    <Stack spacing={2} alignItems="end">
                        <UserEditFormFields
                            control={control}
                            loading={userLoading}
                            roles={getRolesList()}
                            setEditRolesMode={handleSetEditRolesMode}
                        />
                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            alignItems="flex-start"
                            width="100%"
                        >
                            <Stack spacing={2}>
                                <Tooltip
                                    title="Cooming soon!"
                                    placement="right"
                                >
                                    <Button variant="outlined">
                                        Send Invite
                                    </Button>
                                </Tooltip>

                                {
                                    //TODO: Unblock when copy link will be completed
                                    /*                                <LoadingButton
                                    variant="outlined"
                                    disabled={!link}
                                    loading={linkLoading}
                                    onClick={() => {
                                        navigator.clipboard
                                            .writeText(link || '')
                                            .then(() => {
                                                dispatch(
                                                    showMessageAction({
                                                        message:
                                                            'Copied to clipboard!',
                                                        type: 'success'
                                                    })
                                                );
                                            });
                                    }}
                                >
                                    Copy Link
                                </LoadingButton>*/
                                }
                            </Stack>
                            <Stack direction="row" spacing={2}>
                                <Button
                                    onClick={handleCancel}
                                    variant="outlined"
                                    disabled={buttonLoading}
                                >
                                    Cancel
                                </Button>
                                <LoadingButton
                                    onClick={handleSubmit(onSubmit)}
                                    variant="contained"
                                    loading={buttonLoading}
                                >
                                    {isEdit ? 'Save' : 'Continue'}
                                </LoadingButton>
                            </Stack>
                        </Stack>
                    </Stack>
                </form>
            )}
        </Stack>
    );
};
