import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
    Search,
    SearchIconWrapper,
    SearchMenuItem,
    StyledInputBase
} from 'src/components/OrganizationSelect/styles';
import SearchIcon from '@mui/icons-material/Search';
import { Menu } from '@mui/material';
import ObservableLoader from 'src/components/OrganizationSelect/_components/ObservableLoader';
import { IOrganizationDto } from 'src/types';
import { debounce } from 'lodash';
import { apiGetOrganizations } from 'src/api/endpoints/organizations';
import { OnSelectType } from 'src/components/OrganizationSelect/OrganizationSelect';
import MenuItems from 'src/components/OrganizationSelect/_components/MenuItems';

interface OrganizationMenuProps {
    anchorEl: null | HTMLElement;
    onClose: () => void;
    onSelect: OnSelectType;
}

const OrganizationsMenu = ({
    anchorEl,
    onClose,
    onSelect
}: OrganizationMenuProps) => {
    const [organizations, setOrganizations] = useState<IOrganizationDto[]>([]);
    const [searchValue, setSearchValue] = useState('');
    const [showObserver, setShowObserver] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const canLoadMore = useRef(false);
    const nextPage = useRef(0);

    const open = Boolean(anchorEl);

    useEffect(() => {
        if (open) {
            getOrganizationsNow();
        }
    }, [open]);

    const debouncedGetOrganizations = useCallback(
        debounce((searchString: string, page: string) => {
            return apiGetOrganizations({
                params: {
                    params: { searchString },
                    headers: {
                        'X-PageSize': '10',
                        'X-PageNumber': page
                    }
                }
            })
                .then(({ result }) => {
                    setOrganizations(result);

                    if (result.length < 10) {
                        setShowObserver(false);
                    }
                })
                .then(() => {
                    canLoadMore.current = true;
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }, 1000),
        []
    );

    const getOrganizationsNow = (searchValue?: string) => {
        canLoadMore.current = false;
        setShowObserver(true);
        setIsLoading(true);
        apiGetOrganizations({
            params: {
                params: { searchString: searchValue || '' },
                headers: {
                    'X-PageSize': '10',
                    'X-PageNumber': `${nextPage.current}`
                }
            }
        })
            .then(({ result }) => {
                setOrganizations(prevState => [...prevState, ...result]);

                if (result.length < 10) {
                    setShowObserver(false);
                }
            })
            .then(() => {
                canLoadMore.current = true;
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const onChangeSearchInput: React.ChangeEventHandler<
        HTMLTextAreaElement | HTMLInputElement
    > = e => {
        const value = e.target.value;
        setSearchValue(value);
        setOrganizations([]);
        canLoadMore.current = false;
        nextPage.current = 0;
        setShowObserver(true);
        setIsLoading(true);
        debouncedGetOrganizations(value, `${nextPage.current}`);
    };

    const handleSelect = (org: IOrganizationDto) => {
        onSelect(org);
        onClose();
        setSearchValue('');
    };

    const handleChangePageAndGetOrgs = () => {
        if (canLoadMore.current) {
            nextPage.current++;
            getOrganizationsNow(searchValue);
        }
    };

    return (
        <Menu
            open={open}
            anchorEl={anchorEl}
            style={{ minWidth: 300 }}
            onClose={() => {
                onClose();
                setOrganizations([]);
            }}
            sx={{
                '& .MuiMenu-list': { paddingTop: '10px', paddingBottom: 0 }
            }}
            PaperProps={{
                style: {
                    maxHeight: 53 * 5 + 53
                }
            }}
        >
            <SearchMenuItem onKeyDown={e => e.stopPropagation()}>
                <Search>
                    <SearchIconWrapper>
                        <SearchIcon />
                    </SearchIconWrapper>
                    <StyledInputBase
                        value={searchValue}
                        onChange={onChangeSearchInput}
                        placeholder="Search"
                        inputProps={{ 'aria-label': 'search' }}
                    />
                </Search>
            </SearchMenuItem>

            <MenuItems
                onSelect={handleSelect}
                organizations={organizations}
                isLoading={isLoading}
            />

            {showObserver && (
                <ObservableLoader onIntersection={handleChangePageAndGetOrgs} />
            )}
        </Menu>
    );
};

export default OrganizationsMenu;
