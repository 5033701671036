import { Button, InputBase, styled, MenuItem } from '@mui/material';
import Menu from '@mui/material/Menu';
export const Search = styled('div')`
    position: relative;
    margin-left: 0;
    width: 100%;
`;

export const SearchIconWrapper = styled('div')`
    height: 100%;
    position: absolute;
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        paddingLeft: `calc(1em + ${theme.spacing(2)})`,
        width: '100%'
    }
}));

export const SearchMenuItem = styled(MenuItem)`
    background-color: white !important;
    z-index: 1000;
    position: sticky;
    top: 0;
    border-bottom: 1px solid #c0c0c0;

    &:hover {
        background-color: white;
    }

    & > span {
        display: none;
    }
`;

export const LoaderMenuItem = styled(MenuItem)`
    display: flex;
    justify-content: center;
    height: 120px;
`;

export const OrganizationButton = styled(Button)`
    text-transform: unset;
    min-width: 200px;
    justify-content: space-between;
    background-color: transparent;
`;

export const ClearOrganizationButton = styled(Button)`
    background-color: transparent;
`;

export const OrganizationName = styled('div')`
    width: 100%;
`;

export const OrganizationId = styled('div')`
    width: 100%;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.4);
`;
